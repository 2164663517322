import {lazy, Suspense} from 'react';
import {Navigate, useLocation, useRoutes} from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import {CUSTOMER_PATH_AFTER_LOGIN, DRIVER_PATH_AFTER_LOGIN, PATH_AFTER_LOGIN} from '../config';
import {PATH_CUSTOMER, PATH_CUSTOMER_PERMISSIONS} from './paths'
// components
import LoadingScreen from '../components/LoadingScreen';
import RoleBasedGuard from "../guards/RoleBasedGuard";
import {HasAccess} from "../contexts/PermissionsContext";

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const {pathname} = useLocation();

    try {
        return (
            <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')}/>}>
                <Component {...props} />
            </Suspense>
        );
    } catch (e) {
        console.error('Loadable: error', e)
    }
};

export default function Router() {
    return useRoutes([
        {
            path: 'auth',
            children: [
                {
                    path: 'login/:autoLogin',
                    element: (
                        <GuestGuard>
                            <Login/>
                        </GuestGuard>
                    ),
                },
                {
                    path: 'login',
                    element: (
                        <GuestGuard>
                            <Login/>
                        </GuestGuard>
                    ),
                },
                {
                    // path: 'register',
                    path: 'billy',
                    element: (
                        <GuestGuard>
                            <Register/>
                        </GuestGuard>
                    ),
                },
                {path: 'login-unprotected', element: <Login/>},
                {path: 'register-unprotected', element: <Register/>},
                {path: 'verify', element: <VerifyCode/>}
            ],
        },

        // Registration Routs
        {
            path: 'registration',
            element: (
                <AuthGuard>
                    <LogoOnlyLayout/>
                </AuthGuard>
            ),
            children: [
                {path: 'organization', element: <RegisterOrganization/>},
            ],
        },

        // Drive Routes
        {
            path: 'driver',
            element: (
                <AuthGuard>
                    <DashboardLayout/>
                </AuthGuard>
            ),
            children: [
                {
                    element: <Navigate to={DRIVER_PATH_AFTER_LOGIN} replace/>,
                    index: true
                },
                {
                    path: 'app',
                    element: (
                        <DriverJobs/>
                    )
                },
            ]
        },

        // Customer Routes
        {
            path: 'customer',
            element: (
                <AuthGuard>
                    <DashboardLayout/>
                </AuthGuard>
            ),
            children: [
                {
                    element: <Navigate to={CUSTOMER_PATH_AFTER_LOGIN} replace/>,
                    index: true
                },
                {
                    path: 'app',
                    element: (

                        <Welcome/>
                    )
                },
                {
                    path: 'operational/general',
                    element: (
                        <RoleBasedGuard
                            permission={PATH_CUSTOMER_PERMISSIONS[PATH_CUSTOMER.general.app]}
                        >
                            <CustomerGeneralApp/>
                        </RoleBasedGuard>
                    )
                },
                {
                    path: 'coverage',
                    children: [
                        {
                            element: <Navigate to="/customer/coverage/details" replace/>,
                            index: true
                        },
                        {
                            path: 'details',
                            element: (
                                <RoleBasedGuard
                                    permission={PATH_CUSTOMER_PERMISSIONS[PATH_CUSTOMER.coverage.root]}
                                >
                                    <CustomerCoverageDetails/>
                                </RoleBasedGuard>
                            )
                        },
                    ],
                },
                {
                    path: 'analytics',
                    element: (
                        <RoleBasedGuard
                            permission={PATH_CUSTOMER_PERMISSIONS[PATH_CUSTOMER.general.analytics]}
                        >
                            <CustomerGeneralAnalytics/>
                        </RoleBasedGuard>
                    )
                },
                {
                    path: 'ecommerce',
                    element: (
                        <RoleBasedGuard
                            permission={PATH_CUSTOMER_PERMISSIONS[PATH_CUSTOMER.general.ecommerce]}
                        >
                            <CustomerGeneralEcommerce/>
                        </RoleBasedGuard>
                    )
                },
                {
                    path: 'banking',
                    element: (
                        <RoleBasedGuard
                            permission={PATH_CUSTOMER_PERMISSIONS[PATH_CUSTOMER.general.banking]}
                        >
                            <CustomerGeneralBanking/>
                        </RoleBasedGuard>
                    )
                },
                {
                    path: 'booking',
                    element: (
                        <RoleBasedGuard
                            permission={PATH_CUSTOMER_PERMISSIONS[PATH_CUSTOMER.general.booking]}
                        >
                            <CustomerGeneralBooking/>
                        </RoleBasedGuard>
                    )
                },

                {
                    path: 'location',
                    children: [
                        {element: <Navigate to="/customer/location/list" replace/>, index: true},
                        {
                            path: 'list',
                            element: (
                                <RoleBasedGuard
                                    permission={PATH_CUSTOMER_PERMISSIONS[PATH_CUSTOMER.location.list]}
                                >
                                    <CustomerLocationList/>
                                </RoleBasedGuard>
                            )
                        },
                        {
                            path: ':id', element: (
                                <RoleBasedGuard
                                    permission={PATH_CUSTOMER_PERMISSIONS[PATH_CUSTOMER.location.view]}
                                >
                                    <CustomerLocationDetails/>
                                </RoleBasedGuard>
                            )
                        },
                        {
                            path: ':id/edit', element: (
                                <RoleBasedGuard
                                    permission={PATH_CUSTOMER_PERMISSIONS[PATH_CUSTOMER.location.edit]}
                                >
                                    <CustomerLocationEdit/>
                                </RoleBasedGuard>
                            )
                        },
                        {
                            path: 'new', element: (
                                <RoleBasedGuard
                                    permission={PATH_CUSTOMER_PERMISSIONS[PATH_CUSTOMER.location.new]}
                                >
                                    <CustomerLocationCreate/>
                                </RoleBasedGuard>
                            )
                        },
                    ],
                },

                {
                    path: 'service/job',
                    children: [
                        {element: <Navigate to="/customer/service/job/list" replace/>, index: true},
                        {
                            path: 'list',
                            element: (
                                <RoleBasedGuard
                                    permission={"Beachtime Labs:Dolooma:App:Jobs:Read"}
                                >
                                    <CustomerJobList/>
                                </RoleBasedGuard>
                            )
                        },

                        {
                            path: 'new/customer',
                            element: (
                                <RoleBasedGuard
                                    permission={"Beachtime Labs:Dolooma:App:Jobs:Read"}
                                >
                                    <CustomerJobCreateTwo/>
                                </RoleBasedGuard>
                            )
                        },
                        {
                            path: 'view', element: (
                                <RoleBasedGuard
                                    permission={"Beachtime Labs:Dolooma:App:Jobs:Read"}
                                >
                                    <CustomerJobDetails/>
                                </RoleBasedGuard>
                            )
                        },
                        {
                            path: 'edit', element: (
                                <RoleBasedGuard
                                    permission={"Beachtime Labs:Dolooma:App:Jobs:Update"}
                                >
                                    <CustomerJobEdit/>
                                </RoleBasedGuard>
                            )
                        },
                        {
                            path: 'new', element: (
                                <RoleBasedGuard
                                    permission={"Beachtime Labs:Dolooma:App:Jobs:Create"}
                                >
                                    <CustomerJobCreate/>
                                </RoleBasedGuard>
                            )
                        },
                    ],
                },

                {
                    path: 'operations/hours-of-operation',
                    children: [
                        {element: <Navigate to="customer/operations/hours-of-operation/list" replace/>, index: true},
                        {
                            path: 'list', element: (
                                <RoleBasedGuard
                                    permission={PATH_CUSTOMER_PERMISSIONS[PATH_CUSTOMER.hoo.list]}
                                >
                                    <CustomerHooList/>
                                </RoleBasedGuard>
                            )
                        },
                        {
                            path: ':id', element: (
                                <RoleBasedGuard
                                    permission={PATH_CUSTOMER_PERMISSIONS[PATH_CUSTOMER.hoo.view]}
                                >
                                    <CustomerHooDetails/>
                                </RoleBasedGuard>
                            )
                        },
                        {
                            path: ':id/edit', element: (
                                <RoleBasedGuard
                                    permission={PATH_CUSTOMER_PERMISSIONS[PATH_CUSTOMER.hoo.edit]}
                                >
                                    <CustomerHooEdit/>
                                </RoleBasedGuard>
                            )
                        },
                        {
                            path: 'new', element: (
                                <RoleBasedGuard
                                    permission={PATH_CUSTOMER_PERMISSIONS[PATH_CUSTOMER.hoo.new]}
                                >
                                    <CustomerHooCreate/>
                                </RoleBasedGuard>
                            )
                        },
                    ],
                },

                {
                    path: 'service/network/bid',
                    children: [
                        {element: <Navigate to="/customer/service/network/bid/list" replace/>, index: true},
                        {
                            path: 'list',
                            element: (
                                <RoleBasedGuard
                                    permission={PATH_CUSTOMER_PERMISSIONS[PATH_CUSTOMER.networkBid.list]}
                                >
                                    <NetworkBidList/>
                                </RoleBasedGuard>
                            )
                        },
                        {
                            path: ':id',
                            element: (
                                <RoleBasedGuard
                                    permission={PATH_CUSTOMER_PERMISSIONS[PATH_CUSTOMER.networkBid.list]}
                                >
                                    <NetworkBidDetails/>
                                </RoleBasedGuard>
                            )
                        }
                    ]
                },

                {
                    path: 'service/network',
                    children: [
                        {element: <Navigate to="/customer/service/network/list" replace/>, index: true},
                        {
                            path: 'list',
                            element: (
                                <RoleBasedGuard
                                    permission={PATH_CUSTOMER_PERMISSIONS[PATH_CUSTOMER.network.list]}
                                >
                                    <NetworkList/>
                                </RoleBasedGuard>
                            )
                        },
                        {
                            path: ':id', element: (
                                <RoleBasedGuard
                                    permission={PATH_CUSTOMER_PERMISSIONS[PATH_CUSTOMER.network.view]}
                                >
                                    <NetworkDetails/>
                                </RoleBasedGuard>
                            )
                        },
                        {
                            path: ':id/edit', element: (
                                <RoleBasedGuard
                                    permission={PATH_CUSTOMER_PERMISSIONS[PATH_CUSTOMER.network.edit]}
                                >
                                    <NetworkEdit/>
                                </RoleBasedGuard>
                            )
                        },
                        {
                            path: 'new', element: (
                                <RoleBasedGuard
                                    permission={PATH_CUSTOMER_PERMISSIONS[PATH_CUSTOMER.network.new]}
                                >
                                    <NetworkCreate/>
                                </RoleBasedGuard>
                            )
                        },
                        {
                            path: 'provider/profile',
                            element: (
                                <RoleBasedGuard
                                    permission={PATH_CUSTOMER_PERMISSIONS[PATH_CUSTOMER.provider.profile]}
                                >
                                    <ProviderProfile/>
                                </RoleBasedGuard>
                            )
                        },
                    ],
                },

                {
                    path: 'filing/document',
                    children: [
                        {element: <Navigate to="/customer/filing/document/list" replace/>, index: true},
                        {
                            path: 'list', element:
                                (
                                    <RoleBasedGuard
                                        permission={PATH_CUSTOMER_PERMISSIONS[PATH_CUSTOMER.document.list]}
                                    >
                                        <DocumentList/>
                                    </RoleBasedGuard>
                                )
                        },
                    ],
                },
                {
                    path: 'contract',
                    children: [
                        {element: <Navigate to="/customer/contract/list" replace/>, index: true},
                        {
                            path: 'list', element:
                                (
                                    <RoleBasedGuard>
                                        <ContractList/>
                                    </RoleBasedGuard>
                                )
                        },
                        {
                            path: 'new', element:
                                (
                                    <RoleBasedGuard>
                                        <ContractCreate/>
                                    </RoleBasedGuard>
                                )
                        },
                        {
                            path: 'edit', element:
                                (
                                    <RoleBasedGuard>
                                        <ContractEdit/>
                                    </RoleBasedGuard>
                                )
                        },
                        {
                            path: 'details', element:
                                (
                                    <RoleBasedGuard>
                                        <ContractDetails/>
                                    </RoleBasedGuard>
                                )
                        },
                    ],
                },
                {
                    path: 'contract/template',
                    children: [
                        {element: <Navigate to="/customer/contract/template/list" replace/>, index: true},
                        {
                            path: 'list', element:
                                (
                                    <RoleBasedGuard>
                                        <ContractList/>
                                    </RoleBasedGuard>
                                )
                        },
                        {
                            path: 'new', element:
                                (
                                    <RoleBasedGuard>
                                        <ContractCreate/>
                                    </RoleBasedGuard>
                                )
                        },
                        {
                            path: 'edit', element:
                                (
                                    <RoleBasedGuard>
                                        <ContractEdit/>
                                    </RoleBasedGuard>
                                )
                        },
                        {
                            path: 'details', element:
                                (
                                    <RoleBasedGuard>
                                        <ContractDetails/>
                                    </RoleBasedGuard>
                                )
                        },
                    ],
                },
                {
                    path: 'claim',
                    children: [
                        {
                            element: <Navigate to={PATH_CUSTOMER.customerClaims.list} replace/>,
                            index: true
                        },
                        {
                            path: 'list',
                            element: (
                                <RoleBasedGuard
                                    permission={PATH_CUSTOMER_PERMISSIONS[PATH_CUSTOMER.customerClaims.list]}
                                >
                                    <CustomerClaims/>
                                </RoleBasedGuard>
                            )
                        },
                        {
                            path: ':id',
                            element: (
                                <RoleBasedGuard
                                    permission={PATH_CUSTOMER_PERMISSIONS[PATH_CUSTOMER.customerClaims.view]}
                                >
                                    <CustomerClaimDetails/>
                                </RoleBasedGuard>
                            )
                        },
                        {
                            path: ':id/edit',
                            element: (
                                <RoleBasedGuard
                                    permission={PATH_CUSTOMER_PERMISSIONS[PATH_CUSTOMER.customerClaims.edit]}
                                >
                                    <CustomerClaimEdit/>
                                </RoleBasedGuard>
                            )
                        },
                        {
                            path: 'new',
                            element: (
                                <RoleBasedGuard
                                    permission={PATH_CUSTOMER_PERMISSIONS[PATH_CUSTOMER.customerClaims.new]}
                                >
                                    <CustomerClaimCreate/>
                                </RoleBasedGuard>
                            )
                        },
                    ]
                },

                {
                    path: 'invoice',
                    children: [
                        {element: <Navigate to="/customer/invoice/list" replace/>, index: true},
                        {
                            path: 'list',
                            element: (
                                <RoleBasedGuard
                                    permission={PATH_CUSTOMER_PERMISSIONS[PATH_CUSTOMER.customerClaims.new]}
                                >
                                    <CustomerInvoiceList/>
                                </RoleBasedGuard>
                            )
                        },
                        {
                            path: 'view',
                            element: (
                                <RoleBasedGuard
                                    permission={PATH_CUSTOMER_PERMISSIONS[PATH_CUSTOMER.customerClaims.new]}
                                >
                                    <CustomerInvoiceDetails/>
                                </RoleBasedGuard>
                            )
                        },
                        {
                            path: ':id/edit',
                            element: (
                                <RoleBasedGuard
                                    permission={PATH_CUSTOMER_PERMISSIONS[PATH_CUSTOMER.customerClaims.new]}
                                >
                                    <CustomerInvoiceEdit/>
                                </RoleBasedGuard>
                            )
                        },
                        {
                            path: 'new',
                            element: (
                                <RoleBasedGuard
                                    permission={PATH_CUSTOMER_PERMISSIONS[PATH_CUSTOMER.customerClaims.new]}
                                >
                                    <CustomerInvoiceCreate/>
                                </RoleBasedGuard>
                            )
                        },
                    ],
                },
                {
                    path: 'policy',
                    children: [
                        {
                            element: <Navigate to={PATH_CUSTOMER.customerPolicy.list} replace/>,
                            index: true
                        },
                        {
                            path: 'list',
                            element: (
                                <RoleBasedGuard
                                    permission={PATH_CUSTOMER_PERMISSIONS[PATH_CUSTOMER.customerPolicy.list]}
                                >
                                    <CustomerPolicies/>
                                </RoleBasedGuard>
                            )
                        },
                        {
                            path: ':id',
                            element: (
                                <RoleBasedGuard
                                    permission={PATH_CUSTOMER_PERMISSIONS[PATH_CUSTOMER.customerPolicy.view]}
                                >
                                    <CustomerPolicyDetails/>
                                </RoleBasedGuard>
                            )
                        },
                        {
                            path: ':id/edit',
                            element: (
                                <RoleBasedGuard
                                    permission={PATH_CUSTOMER_PERMISSIONS[PATH_CUSTOMER.customerPolicy.edit]}
                                >
                                    <CustomerPolicyEdit/>
                                </RoleBasedGuard>
                            )
                        },
                        {
                            path: 'new',
                            element: (
                                <RoleBasedGuard
                                    permission={PATH_CUSTOMER_PERMISSIONS[PATH_CUSTOMER.customerPolicy.new]}
                                >
                                    <CustomerPolicyCreate/>
                                </RoleBasedGuard>
                            )
                        },
                    ]
                }
            ]
        },

        // Provider Routes
        {
            path: 'operations/center',
            element: (
                <AuthGuard>
                    <DashboardLayout/>
                </AuthGuard>
            ),
            children: [
                {
                    path: 'service',
                    element: <HasAccess page flag={"role_access_operations_center_service_r"}><Service/></HasAccess>
                },
                {
                    path: 'service/dispatch',
                    children: [
                        {element: <Navigate to="/operations/center/service/dispatch/view" replace/>, index: true},
                        {path: 'view', element: <Dispatch/>},
                    ],
                },
                {
                    path: 'inventory',
                    element: <HasAccess page flag={"role_access_operations_center_inventory_r"}><Inventory/></HasAccess>
                },
                {
                    path: 'filing',
                    element:
                        <HasAccess page flag={"role_access_operations_center_filing_r"}>
                            <Filing/>
                        </HasAccess>
                },
                {
                    path: 'communication',
                    element:
                        <HasAccess
                            page
                            flag={"role_access_operations_center_communication_r"}
                        >
                            <Communication/>
                        </HasAccess>
                },
                {
                    path: 'finance',
                    element: <HasAccess page flag={"role_access_operations_center_finance_r"}><Finance/></HasAccess>
                },
                {path: 'service/calendar', element: <Calendar/>},
                {path: 'service/appointment/calendar', element: <ServiceAppointmentCalendar/>},
                {path: 'service/technician/work/schedule', element: <TechnicianWorkSchedule/>},
                {
                    path: 'service/job',
                    children: [
                        {
                            element: <Navigate to="/operations/center/service/job/list" replace/>,
                            index: true
                        },
                        {
                            path: 'list',
                            element: (
                                <HasAccess page flag={"role_access_operations_center_service_jobs_r"}><ProviderJobList/></HasAccess>)
                        },
                        {
                            path: 'view',
                            element: (<HasAccess page
                                                 flag={"role_access_operations_center_service_jobs_r"}><ProviderJobDetails/></HasAccess>)
                        },
                        {
                            path: 'edit',
                            element: (
                                <HasAccess page flag={"role_access_operations_center_service_jobs_u"}><CustomerJobEdit/></HasAccess>)
                        },
                        {
                            path: 'new',
                            element: (<HasAccess page
                                                 flag={"role_access_operations_center_service_jobs_c"}><CustomerJobCreate/></HasAccess>)
                        },

                    ],
                },
                {
                    path: 'service/repair/order',
                    children: [
                        {
                            element: <Navigate to="/operations/center/service/repair/order/list" replace/>,
                            index: true
                        },
                        {
                            path: 'list',
                            element: (
                                <HasAccess page
                                           flag={"role_access_operations_center_service_repair_orders_r"}><RepairOrderList/></HasAccess>)
                        },
                        {
                            path: 'view',
                            element: (<HasAccess page
                                                 flag={"role_access_operations_center_service_repair_orders_r"}><RepairOrderDetails/></HasAccess>)
                        },
                        {
                            path: 'edit',
                            element: (
                                <HasAccess page
                                           flag={"role_access_operations_center_service_repair_orders_r"}><RepairOrderDetails/></HasAccess>)
                        },
                    ],
                },
                {
                    path: 'filing/document',
                    children: [
                        {element: <Navigate to="/operations/center/filing/document/list" replace/>, index: true},
                        {path: 'list', element: <DocumentList/>},
                        {path: 'new', element: <RateCreate/>},
                        {path: ':id/edit', element: <RateEdit/>},
                        {path: ':id', element: <RateDetails/>},
                    ],
                },
                {
                    path: 'filing/contract',
                    children: [
                        {element: <Navigate to="/operations/center/filing/contract/list" replace/>, index: true},
                        {path: 'list', element: <ContractList/>},
                        {path: 'new', element: <ContractCreate/>},
                        {path: 'edit', element: <ContractEdit/>},
                        {path: 'details', element: <ContractDetails/>},
                    ],
                },
                {
                    path: 'filing/contract/template',
                    children: [
                        {
                            element: <Navigate to="/operations/center/filing/contract/template/list" replace/>,
                            index: true
                        },
                        {path: 'list', element: <ContractList/>},
                        {path: 'new', element: <ContractTemplateCreate/>},
                        {path: 'edit', element: <ContractTemplateEdit/>},
                        {path: 'details', element: <ContractTemplateDetails/>},
                    ],
                },
                {
                    path: 'communication/chat',
                    children: [
                        {element: <Chat/>, index: true},
                        {path: 'new', element: <Chat/>},
                        {path: 'view', element: <Chat/>},
                    ],
                },
                {
                    path: 'finance/expense',
                    children: [
                        {element: <Navigate to="/operations/center/finance/expense/list" replace/>, index: true},
                        {path: 'list', element: <ExpenseList/>},
                        {path: 'view', element: <ExpenseDetails/>},
                        {path: 'edit', element: <ExpenseEdit/>},
                        {path: 'new', element: <ExpenseCreate/>},
                    ],
                },
                {
                    path: 'finance/payroll',
                    children: [
                        {element: <Navigate to="/operations/center/finance/payroll/list" replace/>, index: true},
                        {
                            path: 'list',
                            element: <HasAccess page flag={"role_access_operations_center_finance_pre_payroll_r"}>
                                <PrePayrollList/>
                            </HasAccess>
                        },
                        {
                            path: 'edit',
                            element: <HasAccess page flag={"role_access_operations_center_finance_pre_payroll_c"}>
                                <PrePayrollWorksheet/>
                            </HasAccess>
                        },
                    ],
                },
            ]
        },

        {

            path: 'business/center',
            element: (
                <AuthGuard>
                    <DashboardLayout/>
                </AuthGuard>
            ),
            children: [
                {path: 'crm', element: <HasAccess page flag={"role_access_business_center_crm_r"}><Crm/></HasAccess>},
                {
                    path: 'hr',
                    element: <HasAccess page flag={"role_access_business_center_human_resources_r"}><HR/></HasAccess>
                },
                {
                    path: 'pricing',
                    element: <HasAccess page
                                        flag={"role_access_business_center_pricing_offerings_r"}><PricingAndOfferings/></HasAccess>
                },
                {
                    path: 'resources',
                    element: <HasAccess page flag={"role_access_business_center_resources_r"}><Resources/></HasAccess>
                },
                {
                    path: 'operations',
                    element: <HasAccess page flag={"role_access_business_center_operations_r"}><Operations/></HasAccess>
                },
                {
                    path: 'operations/service',
                    children: [
                        {element: <Navigate to="/business/center/operations/service/list" replace/>, index: true},
                        {
                            path: 'list',
                            element: <HasAccess page
                                                flag={"role_access_business_center_operations_services_r"}><ServiceList/></HasAccess>
                        },
                        {
                            path: 'view',
                            element: <HasAccess page
                                                flag={"role_access_business_center_operations_services_r"}><ServiceDetails/></HasAccess>
                        },
                        {
                            path: 'edit',
                            element: <HasAccess page
                                                flag={"role_access_business_center_operations_services_r"}><ServiceEdit/></HasAccess>
                        },
                        {
                            path: 'new',
                            element: <HasAccess page
                                                flag={"role_access_business_center_operations_services_r"}><ServiceCreate/></HasAccess>
                        },
                    ],
                },
                {
                    path: 'operations/hours-of-operation',
                    children: [
                        {
                            element: <Navigate to="/business/center/operations/hours-of-operation/list" replace/>,
                            index: true
                        },
                        {
                            path: 'list', element: <HasAccess page
                                                              flag={"role_access_business_center_operations_hours_of_operation_r"}><HooList/></HasAccess>
                        },
                        {
                            path: ':id', element: <HasAccess page
                                                             flag={"role_access_business_center_operations_hours_of_operation_r"}><HooDetails/></HasAccess>
                        },
                        {
                            path: 'edit', element: <HasAccess page
                                                              flag={"role_access_business_center_operations_hours_of_operation_r"}><HooEdit/></HasAccess>
                        },
                        {
                            path: 'new', element: <HasAccess page
                                                             flag={"role_access_business_center_operations_hours_of_operation_r"}><HooCreate/></HasAccess>
                        },
                    ],
                },
                {
                    path: 'operations/location',
                    children: [
                        {element: <Navigate to="/business/center/operations/location/list" replace/>, index: true},
                        {
                            path: 'list', element: <HasAccess page
                                                              flag={"role_access_business_center_operations_location_coverages_r"}><LocationList/></HasAccess>
                        },
                        {
                            path: 'view', element: <HasAccess page
                                                              flag={"role_access_business_center_operations_location_coverages_r"}><LocationDetails/></HasAccess>
                        },
                        {
                            path: 'edit', element: <HasAccess page
                                                              flag={"role_access_business_center_operations_location_coverages_r"}><LocationEdit/></HasAccess>
                        },
                        {
                            path: 'new', element: <HasAccess page
                                                             flag={"role_access_business_center_operations_location_coverages_r"}><LocationCreate/></HasAccess>
                        },
                    ],
                },
                {
                    path: 'operations/vehicle',
                    children: [
                        {element: <Navigate to="/business/center/operations/vehicle/list" replace/>, index: true},
                        {
                            path: 'list', element: <HasAccess page
                                                              flag={"role_access_business_center_operations_fleet_r"}><VehicleList/></HasAccess>
                        },
                        {
                            path: 'edit', element: <HasAccess page
                                                              flag={"role_access_business_center_operations_fleet_r"}><VehicleEdit/></HasAccess>
                        },
                        {
                            path: 'new', element: <HasAccess page
                                                             flag={"role_access_business_center_operations_fleet_r"}><VehicleCreate/></HasAccess>
                        },
                        {
                            path: 'view', element: <HasAccess page
                                                              flag={"role_access_business_center_operations_fleet_r"}><VehicleView/></HasAccess>
                        },
                    ],
                },
                {
                    path: 'operations/facility',
                    children: [
                        {element: <Navigate to="/business/center/operations/facility/list" replace/>, index: true},
                        {
                            path: 'list', element: <HasAccess page
                                                              flag={"role_access_business_center_operations_facilities_r"}><FacilityList/></HasAccess>
                        },
                        {
                            path: 'edit', element: <HasAccess page
                                                              flag={"role_access_business_center_operations_facilities_r"}><FacilityEdit/></HasAccess>
                        },
                        {
                            path: 'new', element: <HasAccess page
                                                             flag={"role_access_business_center_operations_facilities_r"}><FacilityCreate/></HasAccess>
                        },
                        {
                            path: 'view', element: <HasAccess page
                                                              flag={"role_access_business_center_operations_facilities_r"}><FacilityView/></HasAccess>
                        },
                    ],
                },
                {
                    path: 'operations/insurance',
                    children: [
                        {element: <Navigate to="/business/center/operations/insurance/list" replace/>, index: true},
                        {
                            path: 'list', element: <HasAccess page
                                                              flag={"role_access_business_center_operations_insurance_r"}><InsuranceList/></HasAccess>
                        },
                        {
                            path: 'edit', element: <HasAccess page
                                                              flag={"role_access_business_center_operations_insurance_r"}><InsuranceEdit/></HasAccess>
                        },
                        {
                            path: 'new',
                            element: <HasAccess page
                                                flag={"role_access_business_center_operations_insurance_r"}><InsuranceCreate/></HasAccess>
                        },
                        {
                            path: 'view', element: <HasAccess page
                                                              flag={"role_access_business_center_operations_insurance_r"}><InsuranceView/></HasAccess>
                        },
                    ],
                },
                {
                    path: 'resources/content',
                    children: [
                        {element: <Navigate to="/business/center/resources/content/details" replace/>, index: true},
                        {path: 'details', element: <Training/>},
                        {path: 'purchase', element: <TrainingPurchase/>},
                        {path: 'administration', element: <TrainingAdministration/>},
                        {path: 'product', element: <ContentItemDetails/>}
                    ],
                },
                {
                    path: 'resources/part',
                    children: [
                        {element: <Navigate to="/business/center/resources/part/list" replace/>, index: true},
                        {path: 'list', element: <PartList/>},
                        {path: 'new', element: <PartCreate/>},
                        {path: 'edit', element: <PartEdit/>},
                        {path: 'view', element: <PartDetails/>},
                    ],
                },
                {
                    path: 'hr/user',
                    children: [
                        {element: <Navigate to="/business/center/hr/user/profile" replace/>, index: true},
                        {path: 'profile', element: <UserProfile/>},
                        {path: 'profile/:tab', element: <UserProfile/>},
                        {path: 'cards', element: <UserCards/>},
                        {
                            path: 'list',
                            element: <HasAccess page flag={"role_access_business_center_human_resources_users_r"}>
                                <UserList/>
                            </HasAccess>
                        },
                        {
                            path: 'new',
                            element: <HasAccess page flag={"role_access_business_center_human_resources_users_c"}>
                                <UserCreate/>
                            </HasAccess>
                        },
                        {
                            path: 'edit',
                            element: <HasAccess page flag={"role_access_business_center_human_resources_users_c"}>
                                <UserCreate/>
                            </HasAccess>
                        },
                        {
                            path: 'account',
                            element: <HasAccess page flag={"role_access_owner"}>
                                <UserAccount/>
                            </HasAccess>
                        },
                        {
                            path: 'account/:tabIndex',
                            element: <HasAccess page flag={"role_access_owner"}>
                                <UserAccount/>
                            </HasAccess>
                        },
                        {
                            path: 'account/:tabIndex/:gotab',
                            element: <HasAccess page flag={"role_access_owner"}>
                                <UserAccount/>
                            </HasAccess>
                        },
                        {
                            path: 'account/:tabIndex/:gotab/:fieldName',
                            element: <HasAccess page flag={"role_access_owner"}>
                                <UserAccount/>
                            </HasAccess>
                        },
                    ],
                },
                {
                    path: 'hr/driver',
                    children: [
                        {element: <Navigate to="/business/center/hr/driver/list" replace/>, index: true},
                        {path: 'profile', element: <DriverProfile/>},
                        {
                            path: 'list',
                            element: <HasAccess page
                                                flag={"role_access_business_center_human_resources_users_r"}>
                                <DriverList/>
                            </HasAccess>
                        },
                        {
                            path: 'new',
                            element: <HasAccess page flag={"role_access_business_center_human_resources_users_c"}>
                                <DriverCreate/>
                            </HasAccess>
                        },
                        {
                            path: 'edit',
                            element: <HasAccess page flag={"role_access_business_center_human_resources_users_c"}>
                                <DriverCreate/>
                            </HasAccess>
                        },
                        {
                            path: 'account',
                            element: <HasAccess page flag={"role_access_business_center_human_resources_users_r"}>
                                <DriverAccount/>
                            </HasAccess>
                        },
                        {
                            path: 'schedule',
                            element: <HasAccess page
                                                flag={"role_access_business_center_human_resources_work_schedule_r"}>
                                <DriverSchedule/>
                            </HasAccess>
                        },
                    ],
                },
                {
                    path: 'hr/time-sheet',
                    children: [
                        {element: <Navigate to="/business/center/hr/time-sheet/list" replace/>, index: true},

                        {
                            path: 'list',
                            element: <HasAccess page
                                                flag={"role_access_business_center_human_resources_time_sheet_r"}>
                                <TimeSheetList/>
                            </HasAccess>
                        }
                    ],
                },
                {
                    path: 'pricing/invoice',
                    children: [
                        {element: <Navigate to="/business/center/pricing/invoice/list" replace/>, index: true},
                        {path: 'list', element: <InvoiceList/>},
                        {path: 'view', element: <InvoiceDetails/>},
                        {path: 'edit', element: <InvoiceEdit/>},
                        {path: 'new', element: <InvoiceCreate/>},
                    ],
                },
                {
                    path: 'billing',
                    children: [
                        {element: <Navigate to="/business/center/billing/list" replace/>, index: true},
                        {path: 'list', element: <BillingList/>},
                        {path: 'view', element: <BillingDetails/>}
                    ],
                },
                {
                    path: 'pricing/coverage',
                    children: [
                        {element: <Navigate to="/business/center/pricing/coverage/details" replace/>, index: true},
                        {path: 'details', element: <CoverageDetails/>},
                    ],
                },
                {
                    path: 'pricing/cost',
                    children: [
                        {element: <Navigate to="/business/center/pricing/cost/list" replace/>, index: true},
                        {path: 'list', element: <RateCostList/>},
                        {path: 'new', element: <CostCreate/>},
                        {path: 'edit', element: <CostEdit/>},
                        {path: ':id', element: <CostDetails/>},
                    ],
                },
                {
                    path: 'pricing/rate',
                    children: [
                        {element: <Navigate to="/business/center/pricing/rate/list" replace/>, index: true},
                        {path: 'wizard', element: <RateSetUpWizard/>},
                        {path: 'architect', element: <ServicePortfolioArchitect/>},
                        {path: 'list', element: <RateCostList/>},
                        {path: 'new', element: <RateCreate/>},
                        {path: 'edit', element: <RateEdit/>},
                        {path: 'view', element: <RateDetails/>},
                    ],
                },
            ]
        },

        {

            path: 'resource/center',
            element: (
                <AuthGuard>
                    <DashboardLayout/>
                </AuthGuard>
            ),
            children: [
                {
                    path: 'support',
                    element: <HasAccess page flag={"role_access_resource_center_support_r"}><Support/></HasAccess>
                },
                {
                    path: 'system',
                    element: <HasAccess page flag={"role_access_resource_center_system_r"}><System/></HasAccess>
                },
                {
                    path: 'system/integration',
                    children: [
                        {element: <Navigate to="/resource/center/system/integration/list" replace/>, index: true},
                        {
                            path: 'list',
                            element: <HasAccess page
                                                flag={"role_access_resource_center_system_integrations_r"}><IntegrationList/></HasAccess>
                        },
                        {
                            path: 'details/:id',
                            element: <HasAccess page
                                                flag={"role_access_resource_center_system_integrations_r"}><IntegrationDetails/></HasAccess>
                        },
                        {
                            path: 'details',
                            element: <HasAccess page
                                                flag={"role_access_resource_center_system_integrations_r"}><IntegrationDetails/></HasAccess>
                        },
                        {
                            path: ':id/edit',
                            element: <HasAccess page
                                                flag={"role_access_resource_center_system_integrations_u"}><LocationEdit/></HasAccess>
                        },
                        {
                            path: 'new',
                            element: <HasAccess page
                                                flag={"role_access_resource_center_system_integrations_c"}><LocationCreate/></HasAccess>
                        },
                    ],
                },
            ]
        },

        {
            path: 'dashboard',
            element: (
                <AuthGuard>
                    <DashboardLayout/>
                </AuthGuard>
            ),
            children: [
                {element: <Navigate to={PATH_AFTER_LOGIN} replace/>, index: true},
                {path: 'welcome/home', element: <LoggedInHome/>},
                {path: 'app', element: <Welcome/>},
                {
                    path: 'business',
                    element: <HasAccess page flag={"role_access_dashboard_business_r"}><Business/></HasAccess>
                },
                {
                    path: 'financial',
                    element: <HasAccess page flag={"role_access_dashboard_financial_r"}> <Financial/></HasAccess>
                },
                {
                    path: 'operational',
                    element: <HasAccess page flag={"role_access_dashboard_operational_r"}><Operational/></HasAccess>
                },
                {
                    path: 'operational/general',
                    element:
                        <HasAccess
                            page
                            flag={"role_access_dashboard_operational_jobs_r"}
                        >
                            <GeneralApp/>
                        </HasAccess>
                },
                {
                    path: 'operational/driver',
                    element:
                        <HasAccess
                            page
                            flag={"role_access_dashboard_operational_drivers_r"}
                        >
                            <GeneralDriver/>
                        </HasAccess>
                },
                {
                    path: 'financial/bonus/tracker',
                    element:
                        <HasAccess page flag={"role_access_dashboard_operational_csi_r"}>
                            <GeneralCsi/>
                        </HasAccess>
                },
                {
                    path: 'operational/performance',
                    element:
                        <HasAccess page flag={"role_access_dashboard_financial_bonus_tracker_r"}>
                            <BonusTracker/>
                        </HasAccess>
                },
                {
                    path: 'financial/banking',
                    element: <HasAccess page
                                        flag={"role_access_dashboard_financial_profitability_r"}><GeneralBanking/></HasAccess>
                },
                {
                    path: 'business/ecommerce',
                    element: <HasAccess page
                                        flag={"role_access_dashboard_business_business_health_r"}><GeneralEcommerce/></HasAccess>
                },
                {
                    path: 'operational/analytics',
                    element:
                        <HasAccess
                            page
                            flag={"role_access_dashboard_operational_system_r"}
                        >
                            <GeneralAnalytics/>
                        </HasAccess>
                },
                {path: 'booking', element: <HasAccess page flag={"1.2.3.4"}><GeneralBooking/></HasAccess>},
                {
                    path: 'support/request',
                    children: [
                        {element: <Navigate to="/dashboard/support/request/list" replace/>, index: true},
                        {path: 'list', element: <RequestBoard/>},
                        {path: 'new', element: <RequestBoard/>},
                        {path: ':id/edit', element: <RequestBoard/>},
                        {path: ':id', element: <RequestBoard/>},
                    ],
                },
                {
                    path: 'content',
                    children: [
                        {element: <Navigate to="/dashboard/content/shop" replace/>, index: true},
                        {path: 'shop', element: <ContentShop/>},
                        {path: 'product', element: <ContentItemDetails/>},
                        {path: 'list', element: <ContentItemList/>},
                        {path: 'product/new', element: <ContentItemCreate/>},
                        {path: 'product/:id/edit', element: <ContentItemCreate/>},
                        {path: 'checkout', element: <ContentCheckout/>},
                    ],
                },
                {
                    path: 'carrier',
                    children: [
                        {element: <Navigate to="/dashboard/carrier/list" replace/>, index: true},
                        {path: 'profile', element: <CarrierProfile/>},
                        {path: 'list', element: <CarrierList/>},
                        {path: 'new', element: <CarrierCreate/>},
                        {path: ':id/edit', element: <CarrierCreate/>},
                        {path: 'account', element: <CarrierAccount/>},
                    ],
                },
                {
                    path: 'underwriter',
                    children: [
                        {element: <Navigate to="/dashboard/underwriter/list" replace/>, index: true},
                        {path: 'profile', element: < g/>},
                        {path: 'list', element: <UnderwriterList/>},
                        {path: 'new', element: <UnderwriterCreate/>},
                        {path: ':id/edit', element: <UnderwriterCreate/>},
                        {path: 'account', element: <UnderwriterAccount/>},
                    ],
                },
                {
                    path: 'policy',
                    children: [
                        {element: <Navigate to="/dashboard/policy/list" replace/>, index: true},
                        {path: 'list', element: <PolicyList/>},
                        {path: ':id', element: <PolicyDetails/>},
                        {path: ':id/edit', element: <PolicyEdit/>},
                        {path: 'new', element: <PolicyCreate/>},
                    ],
                },
                {
                    path: 'license',
                    children: [
                        {element: <Navigate to="/dashboard/license/list" replace/>, index: true},
                        {path: 'list', element: <LicenseList/>},
                        {path: ':id', element: <LicenseDetails/>},
                        {path: ':id/edit', element: <LicenseEdit/>},
                        {path: 'new', element: <LicenseCreate/>},
                    ],
                },
                {
                    path: 'crm/account',
                    children: [
                        {element: <Navigate to="/dashboard/crm/account/list" replace/>, index: true},
                        {path: 'list', element: <AccountList/>},
                        {path: ':id', element: <AccountDetails/>},
                        {path: 'edit', element: <AccountEdit/>},
                        {path: 'new', element: <AccountCreate/>},
                    ],
                },
                {
                    path: 'service/opportunity',
                    children: [
                        {element: <Navigate to="/dashboard/service/opportunity/list" replace/>, index: true},
                        {path: 'dashboard', element: <OpportunityDashboard/>},
                        {path: 'list', element: <OpportunityList/>},
                        {path: 'details/:id', element: <OpportunityDetails/>},
                        {path: ':id/edit', element: <OpportunityEdit/>},
                        {path: 'new', element: <OpportunityCreate/>},
                    ],
                },
                {
                    path: 'service/network/bid',
                    children: [
                        {element: <Navigate to="/dashboard/service/network/bid/list" replace/>, index: true},
                        {path: 'list', element: <ProviderNetworkBidList/>},
                        {path: ':id', element: <ProviderNetworkBidDetails/>}
                    ]
                },
                {
                    path: 'mail',
                    children: [
                        {element: <Navigate to="/dashboard/mail/all" replace/>, index: true},
                        {path: 'label/:customLabel', element: <Mail/>},
                        {path: 'label/:customLabel/:mailId', element: <Mail/>},
                        {path: ':systemLabel', element: <Mail/>},
                        {path: ':systemLabel/:mailId', element: <Mail/>},
                    ],
                },
                {path: 'claims', element: <Claims/>},
            ],
        },

        // Main Routes
        {
            path: '*',
            element: <LogoOnlyLayout/>,
            children: [
                {path: 'coming-soon', element: <ComingSoon/>},
                {path: 'maintenance', element: <Maintenance/>},
                {path: '500', element: <Page500/>},
                {path: '404', element: <NotFound/>},
                {path: '401', element: <NoAccess/>},
                {path: '*', element: <Navigate to="/404" replace/>},
            ],
        },
        {
            path: '/',
            element: <MainLayout/>,
            children: [
                {element: <HomePage/>, index: true}
            ],
        },
        {path: '*', element: <Navigate to="/404" replace/>},
    ]);
}

// REGISTRATION
const RegisterOrganization = Loadable(lazy(() => import('../pages/app/Registration')));

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));

// DRIVER
const DriverJobs = Loadable(lazy(() => import('../pages/driver/Home')));

// DASHBOARD
const GeneralEcommerce = Loadable(lazy(() => import('../pages/provider/GeneralEcommerce')));
const GeneralAnalytics = Loadable(lazy(() => import('../pages/provider/GeneralAnalytics')));
const GeneralBanking = Loadable(lazy(() => import('../pages/provider/GeneralBanking')));
const GeneralBooking = Loadable(lazy(() => import('../pages/provider/GeneralBooking')));

// GENERAL
const GeneralDriver = Loadable(lazy(() => import('../pages/provider/GeneralDriver')));
const GeneralApp = Loadable(lazy(() => import('../pages/provider/GeneralApp')));
const GeneralCsi = Loadable(lazy(() => import('../pages/provider/BonusTracker')));
const Welcome = Loadable(lazy(() => import('../pages/app/Welcome')));

// CONTENT
const ContentShop = Loadable(lazy(() => import('../pages/provider/ContentShop')));
const ContentItemDetails = Loadable(lazy(() => import('../pages/provider/ContentItemDetails')));
const ContentItemList = Loadable(lazy(() => import('../pages/provider/ContentItemList')));
const ContentItemCreate = Loadable(lazy(() => import('../pages/provider/ContentItemCreate')));
const ContentCheckout = Loadable(lazy(() => import('../pages/provider/ContentCheckout')));

// CUSTOMER INVOICE
const CustomerInvoiceList = Loadable(lazy(() => import('../pages/customer/InvoiceList')));
const CustomerInvoiceDetails = Loadable(lazy(() => import('../pages/customer/InvoiceDetails')));
const CustomerInvoiceCreate = Loadable(lazy(() => import('../pages/customer/InvoiceCreate')));
const CustomerInvoiceEdit = Loadable(lazy(() => import('../pages/customer/InvoiceEdit')));

// BILLING
const BillingList = Loadable(lazy(() => import('../pages/app/BillingList')));
const BillingDetails = Loadable(lazy(() => import('../pages/app/BillingDetails')));

// INVOICE
const InvoiceList = Loadable(lazy(() => import('../pages/provider/InvoiceList')));
const InvoiceDetails = Loadable(lazy(() => import('../pages/provider/InvoiceDetails')));
const InvoiceCreate = Loadable(lazy(() => import('../pages/provider/InvoiceCreate')));
const InvoiceEdit = Loadable(lazy(() => import('../pages/provider/InvoiceEdit')));

// Policy
const PolicyList = Loadable(lazy(() => import('../pages/provider/PolicyList')));
const PolicyDetails = Loadable(lazy(() => import('../pages/provider/PolicyDetails')));
const PolicyCreate = Loadable(lazy(() => import('../pages/provider/PolicyCreate')));
const PolicyEdit = Loadable(lazy(() => import('../pages/provider/PolicyEdit')));

// Kanban
const RequestBoard = Loadable(lazy(() => import('../pages/app/Request')));

// License
const LicenseList = Loadable(lazy(() => import('../pages/provider/LicenseList')));
const LicenseDetails = Loadable(lazy(() => import('../pages/provider/LicenseDetails')));
const LicenseCreate = Loadable(lazy(() => import('../pages/provider/LicenseCreate')));
const LicenseEdit = Loadable(lazy(() => import('../pages/provider/LicenseEdit')));

// BLOG
// const BlogPosts = Loadable(lazy(() => import('../pages/BlogPosts')));
// const BlogPost = Loadable(lazy(() => import('../pages/BlogPost')));

// USER
const UserProfile = Loadable(lazy(() => import('../pages/app/UserProfile')));
const UserCards = Loadable(lazy(() => import('../pages/app/UserCards')));
const UserList = Loadable(lazy(() => import('../pages/app/UserList')));
const UserAccount = Loadable(lazy(() => import('../pages/app/UserAccount')));
const UserCreate = Loadable(lazy(() => import('../pages/app/UserCreate')));

// Dispatch
const Dispatch = Loadable(lazy(() => import('../pages/provider/Dispatch')));

// Vehicle
const VehicleList = Loadable(lazy(() => import('../pages/provider/VehicleList')));
const VehicleCreate = Loadable(lazy(() => import('../pages/provider/VehicleCreate')));
const VehicleView = Loadable(lazy(() => import('../pages/provider/VehicleDetails')));
const VehicleEdit = Loadable(lazy(() => import('../pages/provider/VehicleEdit')));

// Facility
const FacilityList = Loadable(lazy(() => import('../pages/provider/FacilityList')));
const FacilityCreate = Loadable(lazy(() => import('../pages/provider/FacilityCreate')));
const FacilityView = Loadable(lazy(() => import('../pages/provider/FacilityDetails')));
const FacilityEdit = Loadable(lazy(() => import('../pages/provider/FacilityEdit')));

// Insurance
const InsuranceList = Loadable(lazy(() => import('../pages/provider/InsuranceList')));
const InsuranceCreate = Loadable(lazy(() => import('../pages/provider/InsuranceCreate')));
const InsuranceView = Loadable(lazy(() => import('../pages/provider/InsuranceDetails')));
const InsuranceEdit = Loadable(lazy(() => import('../pages/provider/InsuranceEdit')));

// CARRIER
const CarrierProfile = Loadable(lazy(() => import('../pages/provider/CarrierProfile')));
const CarrierList = Loadable(lazy(() => import('../pages/provider/CarrierList')));
const CarrierAccount = Loadable(lazy(() => import('../pages/provider/CarrierAccount')));
const CarrierCreate = Loadable(lazy(() => import('../pages/provider/CarrierCreate')));

// PAYROLL
const PrePayrollWorksheet = Loadable(lazy(() => import('../sections/@provider/payroll/PrePayrollWorksheet')));
const PrePayrollList = Loadable(lazy(() => import('../pages/provider/PrePayrollList')));

// DRIVER
const DriverProfile = Loadable(lazy(() => import('../pages/provider/DriverProfile')));
const DriverList = Loadable(lazy(() => import('../pages/provider/DriverList')));
const DriverSchedule = Loadable(lazy(() => import('../pages/provider/DriverSchedule')));
const DriverAccount = Loadable(lazy(() => import('../pages/provider/DriverAccount')));
const DriverCreate = Loadable(lazy(() => import('../pages/provider/DriverCreate')));

//TIME SHEET
const TimeSheetList = Loadable(lazy(() => import('../pages/provider/TimeSheetList')));

// UNDERWRITER
const UnderwriterList = Loadable(lazy(() => import('../pages/provider/UnderwriterList')));
const UnderwriterAccount = Loadable(lazy(() => import('../pages/provider/UnderwriterAccount')));
const UnderwriterCreate = Loadable(lazy(() => import('../pages/provider/UnderwriterCreate')));

// Rates
const RateSetUpWizard = Loadable(lazy(() => import('../pages/app/RateSetUpWizard')));
const RateCostList = Loadable(lazy(() => import('../pages/provider/RateCostList')));
const RateCreate = Loadable(lazy(() => import('../pages/provider/RateCreate')));
const RateEdit = Loadable(lazy(() => import('../pages/provider/RateEdit')));
const RateDetails = Loadable(lazy(() => import('../pages/provider/RateDetails')));
const ServicePortfolioArchitect = Loadable(lazy(() => import('../pages/app/ServicePortfolioArchitect')));

// Costs
const CostCreate = Loadable(lazy(() => import('../pages/provider/CostCreate')));
const CostEdit = Loadable(lazy(() => import('../pages/provider/CostEdit')));
const CostDetails = Loadable(lazy(() => import('../pages/provider/CostDetails')));


// Documents
const DocumentList = Loadable(lazy(() => import('../pages/app/DocumentList')));

// Contracts
const ContractList = Loadable(lazy(() => import('../pages/app/ContractList')));
const ContractCreate = Loadable(lazy(() => import('../pages/app/ContractCreate')));
const ContractEdit = Loadable(lazy(() => import('../pages/app/ContractEdit')));
const ContractDetails = Loadable(lazy(() => import('../pages/app/ContractDetails')));

// Contract Templates
const ContractTemplateCreate = Loadable(lazy(() => import('../pages/app/ContractTemplateCreate')));
const ContractTemplateEdit = Loadable(lazy(() => import('../pages/app/ContractTemplateEdit')));
const ContractTemplateDetails = Loadable(lazy(() => import('../pages/app/ContractTemplateDetails')));


// SERVICES
const ServiceList = Loadable(lazy(() => import('../pages/provider/ServiceList')));
const ServiceCreate = Loadable(lazy(() => import('../pages/provider/ServiceCreate')));
const ServiceEdit = Loadable(lazy(() => import('../pages/provider/ServiceEdit')));
const ServiceDetails = Loadable(lazy(() => import('../pages/provider/ServiceDetails')));

// HOURS OF OPERATION
const HooList = Loadable(lazy(() => import('../pages/provider/HooList')));
const HooCreate = Loadable(lazy(() => import('../pages/provider/HooCreate')));
const HooEdit = Loadable(lazy(() => import('../pages/provider/HooEdit')));
const HooDetails = Loadable(lazy(() => import('../pages/provider/HooDetails')));

// COVERAGE
const CoverageDetails = Loadable(lazy(() => import('../pages/provider/CoverageDetails')));

// COVERAGE
const CustomerCoverageDetails = Loadable(lazy(() => import('../pages/customer/CoverageDetails')));

// LOCATIONS
const LocationList = Loadable(lazy(() => import('../pages/provider/LocationList')));
const LocationCreate = Loadable(lazy(() => import('../pages/provider/LocationCreate')));
const LocationEdit = Loadable(lazy(() => import('../pages/provider/LocationEdit')));
const LocationDetails = Loadable(lazy(() => import('../pages/provider/LocationDetails')));

// EXPENSES
const ExpenseList = Loadable(lazy(() => import('../pages/provider/ExpenseList')));
const ExpenseCreate = Loadable(lazy(() => import('../pages/provider/ExpenseCreate')));
const ExpenseEdit = Loadable(lazy(() => import('../pages/provider/ExpenseEdit')));
const ExpenseDetails = Loadable(lazy(() => import('../pages/provider/ExpenseDetails')));

// LOCATIONS
const AccountList = Loadable(lazy(() => import('../pages/provider/AccountList')));
const AccountCreate = Loadable(lazy(() => import('../pages/provider/AccountCreate')));
const AccountEdit = Loadable(lazy(() => import('../pages/provider/AccountEdit')));
const AccountDetails = Loadable(lazy(() => import('../pages/provider/AccountDetails')));

// PARTS
const PartList = Loadable(lazy(() => import('../pages/provider/PartList')));
const PartCreate = Loadable(lazy(() => import('../pages/provider/PartCreate')));
const PartEdit = Loadable(lazy(() => import('../pages/provider/PartEdit')));
const PartDetails = Loadable(lazy(() => import('../pages/provider/PartDetails')));

// CUSTOMER JOBS
const CustomerJobList = Loadable(lazy(() => import('../pages/customer/JobRequestList')));
const CustomerJobCreate = Loadable(lazy(() => import('../pages/customer/JobRequestCreate')));
const CustomerJobCreateTwo = Loadable(lazy(() => import('../pages/customer/JobRequestCustomerCreate')));
const CustomerJobDetails = Loadable(lazy(() => import('../pages/customer/JobRequestDetails')));
const CustomerJobEdit = Loadable(lazy(() => import('../pages/customer/JobRequestEdit')));

// REPAIR ORDERS
const RepairOrderList = Loadable(lazy(() => import('../pages/provider/RepairOrderList')));
const RepairOrderDetails = Loadable(lazy(() => import('../pages/provider/RepairOrderDetails')));

// PROVIDER JOBS
const ProviderJobList = Loadable(lazy(() => import('../pages/provider/JobRequestList')));
// const ProviderJobCreate = Loadable(lazy(() => import('../pages/customer/JobRequestCreate')));
const ProviderJobDetails = Loadable(lazy(() => import('../pages/provider/JobRequestDetails')));
// const ProviderJobEdit = Loadable(lazy(() => import('../pages/customer/JobRequestEdit')));

// CUSTOMER LOCATIONS
const CustomerLocationList = Loadable(lazy(() => import('../pages/customer/LocationList')));
const CustomerLocationCreate = Loadable(lazy(() => import('../pages/customer/LocationCreate')));
const CustomerLocationDetails = Loadable(lazy(() => import('../pages/customer/LocationDetails')));
const CustomerLocationEdit = Loadable(lazy(() => import('../pages/customer/LocationEdit')));

// HOURS OF OPERATION
const CustomerHooList = Loadable(lazy(() => import('../pages/customer/HooList')));
const CustomerHooCreate = Loadable(lazy(() => import('../pages/customer/HooCreate')));
const CustomerHooEdit = Loadable(lazy(() => import('../pages/customer/HooEdit')));
const CustomerHooDetails = Loadable(lazy(() => import('../pages/customer/HooDetails')));

// CUSTOMER BIDS
const NetworkBidList = Loadable(lazy(() => import('../pages/customer/NetworkBidList')));
const NetworkBidDetails = Loadable(lazy(() => import('../pages/customer/NetworkBidDetails')));

// PROVIDER BIDS
const ProviderNetworkBidList = Loadable(lazy(() => import('../pages/provider/NetworkBidList')));
const ProviderNetworkBidDetails = Loadable(lazy(() => import('../pages/provider/NetworkBidDetails')));

// CUSTOMER PROVIDER
const ProviderProfile = Loadable(lazy(() => import('../pages/customer/ProviderProfile')));

// CUSTOMER NETWORK
const NetworkList = Loadable(lazy(() => import('../pages/customer/NetworkList')));
const NetworkCreate = Loadable(lazy(() => import('../pages/customer/NetworkCreate')));
const NetworkEdit = Loadable(lazy(() => import('../pages/customer/NetworkEdit')));
const NetworkDetails = Loadable(lazy(() => import('../pages/customer/NetworkDetails')));

// OPPORTUNITY
const OpportunityList = Loadable(lazy(() => import('../pages/provider/OpportunityList')));
const OpportunityDetails = Loadable(lazy(() => import('../pages/provider/OpportunityDetails')));
const OpportunityCreate = Loadable(lazy(() => import('../pages/provider/OpportunityCreate')));
const OpportunityEdit = Loadable(lazy(() => import('../pages/provider/OpportunityEdit')));
const OpportunityDashboard = Loadable(lazy(() => import('../pages/provider/OpportunityDashboard')));

// INTEGRATION
const IntegrationList = Loadable(lazy(() => import('../pages/app/IntegrationList')));
const IntegrationDetails = Loadable(lazy(() => import('../pages/app/IntegrationDetails')));

// CUSTOMER DASHBOARD
const CustomerGeneralAnalytics = Loadable(lazy(() => import('../pages/customer/GeneralAnalytics')));
const CustomerGeneralEcommerce = Loadable(lazy(() => import('../pages/customer/GeneralEcommerce')));
const CustomerGeneralBanking = Loadable(lazy(() => import('../pages/customer/GeneralBanking')));
const CustomerGeneralBooking = Loadable(lazy(() => import('../pages/customer/GeneralBooking')));
const BonusTracker = Loadable(lazy(() => import('../pages/provider/Performance')));

// GENERAL
const CustomerGeneralApp = Loadable(lazy(() => import('../pages/customer/GeneralApp')));

// Service
const Service = Loadable(lazy(() => import('../pages/app/Service')));
const ServiceAppointmentCalendar = Loadable(lazy(() => import('../pages/provider/ServiceAppointmentCalendar')));
const TechnicianWorkSchedule = Loadable(lazy(() => import('../pages/provider/TechnicianWorkSchedule')));

// Inventory
const Inventory = Loadable(lazy(() => import('../pages/app/Inventory')));

// HR
const HR = Loadable(lazy(() => import('../pages/app/HumanResources')));

// Operations
const Operations = Loadable(lazy(() => import('../pages/app/Operations')));

// Crm
const Finance = Loadable(lazy(() => import('../pages/app/Finance')));

// Crm
const Crm = Loadable(lazy(() => import('../pages/app/Crm')));

// Filing
const Filing = Loadable(lazy(() => import('../pages/app/Filing')));

// Communication
const Communication = Loadable(lazy(() => import('../pages/app/Communication')));

// Support
const Support = Loadable(lazy(() => import('../pages/app/Support')));

// System
const System = Loadable(lazy(() => import('../pages/app/System')));

// Business
const Business = Loadable(lazy(() => import('../pages/app/Business')));

// Operational
const Operational = Loadable(lazy(() => import('../pages/app/Operational')));

// Financial
const Financial = Loadable(lazy(() => import('../pages/app/Financial')));

// Pricing And Offerings
const PricingAndOfferings = Loadable(lazy(() => import('../pages/app/PricingAndOfferings')));

// Resources
const Resources = Loadable(lazy(() => import('../pages/app/Resources')));

// TRAINING
const Training = Loadable(lazy(() => import('../pages/provider/Training')));
const TrainingPurchase = Loadable(lazy(() => import('../pages/provider/ContentShop')));
const TrainingAdministration = Loadable(lazy(() => import('../pages/provider/ContentAdministration')));

// CUSTOMER CLAIMS
const CustomerClaims = Loadable(lazy(() => import('../pages/customer/ClaimsList')));
const CustomerClaimDetails = Loadable(lazy(() => import('../pages/customer/ClaimDetails')));
const CustomerClaimCreate = Loadable(lazy(() => import('../pages/customer/ClaimCreate')));
const CustomerClaimEdit = Loadable(lazy(() => import('../pages/customer/ClaimEdit')));

// CUSTOMER POLICIES
const CustomerPolicies = Loadable(lazy(() => import('../pages/customer/PolicyList')));
const CustomerPolicyDetails = Loadable(lazy(() => import('../pages/customer/PolicyDetails')));
const CustomerPolicyCreate = Loadable(lazy(() => import('../pages/customer/PolicyCreate')));
const CustomerPolicyEdit = Loadable(lazy(() => import('../pages/customer/PolicyEdit')));

// APP
const Chat = Loadable(lazy(() => import('../pages/app/Chat')));
const Mail = Loadable(lazy(() => import('../pages/app/Mail')));
const Calendar = Loadable(lazy(() => import('../pages/app/Calendar')));
const Claims = Loadable(lazy(() => import('../pages/app/Claims')));


// MAIN
const LoggedInHome = Loadable(lazy(() => import('../pages/LoggedInHome')));
const HomePage = Loadable(lazy(() => import('../pages/Home')));
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
const NoAccess = Loadable(lazy(() => import('../pages/Page401')));

